import React from "react";

// Material UI
import { AppBar, Container, Toolbar, Box, Typography } from "@mui/material";
import NavLogo from "./NavLogo";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Navbar = () => {
  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          py: 0,
          borderBottom: "1px solid #dddddd",
        }}
        position="static"
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography fontWeight={900} variant="h3" component="h3">
                TNPSC{" "}
                <Typography
                  color="text.primary"
                  fontWeight={900}
                  variant="h3"
                  component="span"
                >
                  PYQ
                </Typography>
              </Typography>
              {/* <NavLogo /> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
