export const themeColors = {
  paper: "#ffffff",
  darkPaper: "#333333",

  // Primary
  primaryLight: "#dbeaff", //#5082ef
  primaryMain: "#2563eb",
  primaryDark: "#1945a4",
  primary200: "#dbeaff", //#d3e5ff
  primary800: "#fe8c0b",

  // Secondary
  secondaryLight: "#ba68c8",
  secondaryMain: "#9c27b0",
  secondaryDark: "#7b1fa2",
  secondary200: "#ffa690",
  secondary800: "#e02c13",

  // Success
  successLight: "#4caf50",
  successMain: "#2e7d32",
  successDark: "#1b5e20",
  success200: "#a2daa0",
  success800: "#16831b",
  success50: "#e7f6e7",

  // Error
  errorLight: "#ef5350",
  errorMain: "#d32f2f",
  errorDark: "#c62828",

  // orange
  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  // warning
  warningLight: "#ff9800",
  warningMain: "#ed6c02",
  warningDark: "#e65100",

  // Grey
  // grey50: "#fafafa",
  // grey100: "#f5f5f5",
  // grey200: "#eeeeee",
  // grey300: "#e0e0e0",
  // grey400: "#bdbdbd",
  // grey500: "#9e9e9e",
  // grey600: "#757575",
  // grey700: "#616161",
  // grey800: "#333333",
  // grey900: "#212121",
};
